import React from "react";
import ErrorPng from "../assests/wrong.webp";

const Terminate = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textalign: "center",
        height: "90vh",
        width: "100vw",
        fontSize: "20px",
        fontWeight: "600",
      }}
    >
      <img src={ErrorPng} alt="" />
      Oops! Somthing Went Wrong....
    </div>
  );
};

export default Terminate;
