import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../components/question.css";
import RespondentFlowApi from "../services/respondentFlow.api";
import { updateStatus } from "../services/respondent.api";
import BounceLoader from "react-spinners/ClipLoader";
import * as StatusConstants from "../constants/status.constants";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import LoggerService from "../services/logger.service";

const ProfilerQuesion = () => {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(0);
  const [traceId, setTraceId] = useState("");
  const [token, setToken] = useState("");
  const [tokenId, setTokenId] = useState(0);
  const [Loader, setLoader] = useState(true);
  let navigate = useNavigate();
  const [idx, setIdx] = useState(0);
  const [allData, setAllData] = useState([]);
  const [match, setMatch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorIndex, setErrorIndex] = useState([]);

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, []);

  let getQuestions = () => {
    let params = sessionStorage.getItem("params");
    let paramObj = JSON.parse(params);
    if (paramObj === null) {
      navigate("/error");
      return;
    }
    setToken(paramObj.token);
    setTokenId(paramObj.tokenId);
    LoggerService.log(paramObj.token, "", "Getting profiler questions");
    RespondentFlowApi.post("/v1/profiling/", params)
      .then(async (response) => {
        setLoader(false);
        if (response.questionList.length === 0) {
          LoggerService.log(
            paramObj.token,
            "",
            "No profiling question received"
          );
          await updateStatus(
            response.token,
            paramObj.tokenId,
            StatusConstants.STATUS_PROFILER_START,
            StatusConstants.SUB_STATUS_PROFILER_TO_SCREENER
          );
          LoggerService.log(
            paramObj.token,
            "",
            "Redirecting to Start",
            `/start?token=${response.token}`
          );
          navigate(`/start?token=${response.token}`);
          return;
        }

        //TODO:: Remove this code
        let questionList = response.questionList.map((question) => {
          if (question.type === 1 || question.type === 2) {
            question.answerId = [];
          } else {
            question.answerText = null;
          }
          return question;
        });
        LoggerService.log(
          paramObj.token,
          "",
          "Profiling questions received",
          JSON.stringify(questionList)
        );
        setUserId(response.userId);
        setTraceId(response.traceId);

        setAllData(questionList);
        LoggerService.log(
          paramObj.token,
          "",
          "Displaying the 1st question " + questionList[0].text,
          JSON.stringify(questionList[0])
        );
      })
      .catch(async (e) => {
        setLoader(false);
        console.error(e);
        navigate(`/error`);
      });
  };

  let saveRsponse = (event) => {
    event.preventDefault();
    if (errorIndex.length <= 0) {
      setIsLoading(true);
      let reqObj = {};
      reqObj.userId = userId;
      reqObj.responses = data;
      reqObj.traceId = traceId;
      reqObj.token = token;
      reqObj.tokenId = tokenId;
      LoggerService.log(
        token,
        "",
        "Going to save profiler user response",
        JSON.stringify(reqObj)
      );
      RespondentFlowApi.post(`/v1/profiling/response?token=${token}`, reqObj)
        .then(async (response) => {
          LoggerService.log(
            token,
            "",
            "User response saved on server",
            JSON.stringify(response)
          );
          if (idx + 1 <= allData.length - 1) {
            LoggerService.log(
              token,
              "",
              "Displaying next question " + allData[idx + 1].text
            );
            setErrorIndex([]);
            setMatch(true);
            setIdx(idx + 1);
          } else {
            setErrorIndex([]);
            setMatch(true);
            LoggerService.log(
              token,
              "",
              "Redirecting to Start",
              `/start?token=${token}`
            );
            await updateStatus(
              token,
              tokenId,
              StatusConstants.STATUS_PROFILER_START,
              StatusConstants.SUB_STATUS_PROFILER_TO_SCREENER
            );
            navigate(`/start?token=${token}`);
          }
          setIsLoading(false);
          setErrorIndex([]);
          setMatch(true);
        })
        .catch(async (e) => {
          LoggerService.log(
            token,
            "",
            "Error in saving profiler response ",
            JSON.stringify(e)
          );
          setIsLoading(false);
          navigate(`/error`);
        });
    }
  };

  useEffect(() => {
    let payload = [...allData];
    let slicePayload = payload.slice(idx, idx + 1);
    setData(slicePayload);
  }, [allData, idx]);

  useEffect(() => {
    let indexError = [];
    data.map((value, i) => {
      if (
        value?.answerId?.length === 0 ||
        value?.answerText === null ||
        value?.answerText === "" ||
        (value.id === 59 && value?.answerText > 99) ||
        (value.id === 59 && value?.answerText < 18)
      ) {
        if (!indexError.includes(i)) {
          indexError.push(i);
        }
      }
      return true;
    });
    setErrorIndex(indexError);
  }, [data]);

  const responseData = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {Loader ? (
        <>
          <div className="flex justify-center h-screen bg-gray-200">
            <div>
              <BounceLoader
                color="rgb(129, 176, 60)"
                loading={true}
                size={60}
                textalign="center"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <span
                style={{
                  fontWeight: "700",
                  marginTop: "20px",
                  display: "block",
                  textalign: "center",
                  color: "rgb(129, 176, 60)",
                }}
              >
                {"Loading..."}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-gray-200  h-[100vh] flex justify-center     p-2">
          <div className="questionCard">
            <form
              onSubmit={isLoading ? responseData : saveRsponse}
              noValidate
              className="h-[100%] relative"
            >
              <div className="question-body">
                <div>
                  {data.map((value, i) => {
                    return (
                      <div key={i} className="mb-10">
                        <h1 className="question-text">{value?.text}</h1>
                        {value.type === 1 ? (
                          <>
                            {!match && errorIndex.includes(i) && (
                              <div
                                id="example"
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "13px",
                                }}
                              >
                                This field is required!
                              </div>
                            )}
                            <div
                              className={
                                value?.options.length > 12
                                  ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                                  : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                              }
                            >
                              {value?.options.map((item, index) => (
                                <div className="flex  space-x-3" key={index}>
                                  <input
                                    type="radio"
                                    id={`radio_${item.id}`}
                                    name={value?.id}
                                    checked={value.answerId.includes(item.id)}
                                    onChange={(e, value) => {
                                      let payload = [...data];
                                      payload[i].answerId = [item.id];

                                      setData(payload);
                                    }}
                                    className="cursor-pointer"
                                  />
                                  <label
                                    className="text-sm font-medium cursor-pointer"
                                    htmlFor={`radio_${item.id}`}
                                  >
                                    {item?.text}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <div className="flex flex-row-reverse w-full md:w-[100%] px-8 gap-5 h-fit	pt-4 pb-4 bg-white absolute bottom-0">
                              <button
                                type="submit"
                                className="button"
                                onClick={() => {
                                  setMatch(false);
                                }}
                              >
                                Next
                              </button>
                            </div>
                          </>
                        ) : value?.type === 4 || value?.type === 3 ? (
                          <>
                            <TextField
                              type={value.id===59?"number":"text"}
                              size="small"
                              autoComplete="off"
                              className="mt-2 font-medium text-sm"
                              value={
                                value.answerText === null
                                  ? ""
                                  : value.answerText
                              }
                              onChange={(e) => {
                                let payload = [...data];
                                payload[i].answerText = e.target.value;
                                setData(payload);
                              }}
                              error={
                                !match && errorIndex.includes(i) ? true : false
                              }
                            />

                            {!match && errorIndex.includes(i) && (
                              <div
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "13px",
                                }}
                              >
                                {value.answerText > 99 && value.id === 59
                                  ? "This value not be greater then 100 and"
                                  : value.answerText < 18 && value.id === 59
                                  ? "This value not be Less then 18"
                                  : "This field is required!"}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {!match && errorIndex.includes(i) && (
                              <div
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "13px",
                                }}
                              >
                                This field is required!
                              </div>
                            )}
                            <div
                              className={
                                value?.options.length > 12
                                  ? "grid md:grid-cols-2 gap-4 grid-cols-1 mt-2"
                                  : "grid md:grid-cols-1 gap-4 grid-cols-1 mt-2"
                              }
                            >
                              {value?.options.map((item, index) => {
                                return (
                                  <div
                                    className=" flex space-x-3 items-center"
                                    key={index}
                                  >
                                    <input
                                      id={`checkbox_${item.id}`}
                                      type="checkbox"
                                      name={value?.id}
                                      className="cursor-pointer"
                                      checked={value.answerId.includes(item.id)}
                                      onChange={(e, value) => {
                                        let payload = [...data];
                                        if (!e.target.checked) {
                                          payload[i].answerId = payload[
                                            i
                                          ].answerId.filter(
                                            (answer) => answer !== item.id
                                          );
                                        } else {
                                          payload[i].answerId.push(item.id);
                                        }
                                        setData(payload);
                                      }}
                                    />
                                    <label
                                      className="font-medium text-sm cursor-pointer"
                                      htmlFor={`checkbox_${item.id}`}
                                    >
                                      {item?.text}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-row-reverse w-full md:w-[100%] px-8 gap-5 h-fit	pt-4 pb-4 bg-white absolute bottom-0">
                <button
                  type="submit"
                  className="button"
                  onClick={() => {
                    setMatch(false);
                  }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilerQuesion;
