import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Ending from "./pages/Ending";
import Landing from "./pages/Landing";
import ScreenQuestion from "./pages/ScreenQuestion";
import Success from "./pages/Success";
import ProfilerQuesion from "./pages/ProfilerQuesion";
import Error from "./pages/Error";
import Start from "./pages/Start";
import Terminate from "./pages/Terminate";
import DynataEnding from "./pages/DynataEnding";
import FpLanding from "./pages/FpLanding";

function App() {
  return (
    <div className="App h-screen ">
      <BrowserRouter>
        <Routes>
          <Route path="/landing" element={<Landing />} />
          <Route path="/screener" element={<ScreenQuestion />} />
          <Route path="/profiling" element={<ProfilerQuesion />} />
          {/* start */}
          <Route path="/start" element={<Start />} />
          <Route path="/success" element={<Success />} />
          <Route path="/ending" element={<Ending />} />
          <Route path="/ending/dynata" element={<DynataEnding />} />
          <Route path="/error" element={<Error />} />
          <Route path="/terminate" element={<Terminate />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
