import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import RespondentFlowApi from "../services/respondentFlow.api";
// import {
//   CacheLocation,
//   FpjsProvider,
//   useVisitorData,
// } from "@fingerprintjs/fingerprintjs-pro-react";
import LoggerService from "../services/logger.service";
import useQueryParams from "../utils/query.params";
import environment from "../environments/environment.json";
import { useMediaQuery } from "@mui/material";
import axios from "axios";

const Landing = () => {
  // const [loadOptions] = useState({
  //   apiKey: environment.FINGERPRINT,
  // });
  const [Loader] = useState(true);
  const [paramActive, setParamActive] = useState(false);
  const [deviceType, setDeviceType] = useState(0);
  const IsMobile = useMediaQuery("(min-width:480px)");
  const IsTablet = useMediaQuery("(min-width:768px)");
  const isLaptopsAndLarger = useMediaQuery("(min-width:1200px)");
  const [beaconUrl, setBeaconUrl] = useState(null);
  const [rdEnable, setRdEnable] = useState(null);

  const rdBaseUrl = environment.RD_BASE_URL + environment.RD_PUBLISHABLE_KEY;

  useEffect(() => {
    if (isLaptopsAndLarger) {
      setDeviceType(1);
    } else if (IsTablet) {
      setDeviceType(3);
    } else if (IsMobile) {
      setDeviceType(2);
    } else {
      setDeviceType(2);
    }
  }, [IsTablet, isLaptopsAndLarger]);
  // const { data, isLoading, error } = useVisitorData(
  //   { extendedResult: true },
  //   { immediate: true }
  // );
  // setFpLoading(isLoading);
  let params = {};

  useQueryParams().forEach((value, key) => {
    params[key] = value;
  });

  let navigate = useNavigate();
  useEffect(() => {
    if (params) {
      if (paramActive === false) setParamActive(true);
    } else {
      navigate("/error");
    }
    // eslint-disable-next-line
  }, [params]);

  let getRDToken = async (userId) => {
    let rdUrl = `${rdBaseUrl}?rt_sr_pd=${userId}`;
    let { data, status } = await axios.get(rdUrl);
    if (status !== 200) {
      return null;
    }

    return data.results[0];
  };

  let runRDS2SCheck = async (token, rdToken, surveyId) => {
    let payload = {
      token: token,
      rdToken: rdToken,
    };

    try {
      let data = await RespondentFlowApi.post(`/v1/landing/security/check`, payload);
      return data;
    } catch (e) {
      LoggerService.log(params.e_token, "", "Error on landing page", JSON.stringify(e));
      return null;
    }
  };

  let getStatus = async () => {
    let payload = { ...params };
    payload.fp_data = null;
    payload.device_type = deviceType;
    payload.url = window.location.href;
    LoggerService.log("", params.e_token, "New Click received - " + payload.url);
    try {
      let data = await RespondentFlowApi.post(`/v1/landing`, payload);
      LoggerService.log(data.token, params.e_token, "Clieck registered with token - " + data.token);
      let obj = {};
      obj.paramList = data.paramList;
      obj.sessionUid = data.sessionUid;
      obj.surveyUid = data.surveyUid;
      obj.vendorUid = data.vendorUid;
      obj.token = data.token;
      obj.tokenId = data.tokenId;
      obj.userUid = data.userUid;
      obj.userId = data.userId;
      obj.traceId = data.traceId;
      sessionStorage.removeItem("params");
      sessionStorage.setItem("params", JSON.stringify(obj));

      if (data.securityParams.researchDefender == true) {
        setRdEnable(data.securityParams.researchDefender);

        let rdResp = await getRDToken(data.userId);

        if (rdResp && rdResp.token) {
          let securityCheckResp = await runRDS2SCheck(data.token, rdResp.token);
          //if fail redirect to supplier
          if (securityCheckResp.status == false) {
            window.location.href = `${securityCheckResp.supplierUrl}`;
            return;
          }
        }

        //get rd token
        // check rd validation via token
        // if valid
        //else redirect to supplier
      }

      if (data.runBeacon == true) {
        setBeaconUrl(data.beaconUrl);
      }

      if (data.isExternalUrl === true) {
        LoggerService.log(data.token, "", "Respondent redirected to external link " + data.url, data.isExternalUrl);
        sessionStorage.removeItem("params");
        if (data.beaconUrl) {
          setTimeout(() => {
            window.location.href = `${data.url}`;
          }, 500);
        } else {
          window.location.href = `${data.url}`;
        }
      } else {
        LoggerService.log(data.token, "", "Respondent navigated to internal link", `${data.url}?token=${data.token}`);
        if (data.beaconUrl) {
          setTimeout(() => {
            console.log("I am called with timeout");
            navigate(`${data.url}?token=${data.token}`);
          }, 500);
        } else {
          console.log("I am called w/o timeout");
          navigate(`${data.url}?token=${data.token}`);
        }
      }
    } catch (e) {
      LoggerService.log(params.e_token, "", "Error on landing page", JSON.stringify(e));
      navigate(`/error`);
    }
  };

  useEffect(() => {
    if (paramActive) getStatus();

    // eslint-disable-next-line
  }, [paramActive, deviceType]);

  return (
    // <FpjsProvider
    //   loadOptions={loadOptions}
    //   cacheLocation={CacheLocation.LocalStorage}
    //   cachePrefix={environment.FINGERPRINT_CACHE_KEY}
    // >
    beaconUrl ? (
      <img style={{ display: "none" }} src={beaconUrl}></img>
    ) : Loader ? (
      <>
        <div className="flex justify-center items-center h-screen bg-gray-200">
          <div>
            <BounceLoader
              color="rgb(129, 176, 60)"
              loading={true}
              size={60}
              textalign="center"
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <span
              style={{
                fontWeight: "700",
                marginTop: "20px",
                display: "block",
                textalign: "center",
                color: "rgb(129, 176, 60)",
              }}
            >
              {"Loading..."}
            </span>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="flex  justify-center">
          <img src="https://img.icons8.com/bubbles/256/man-with-a-clock.png" alt="" />
        </div>
      </>
    )
    // </FpjsProvider>
  );
};

export default Landing;
